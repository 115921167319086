import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { Tree } from './Tree';
import { AppStore } from '../../store/applicationState';
import { IRubric } from '../../store/rubrics/types';
import { CreateRubric } from '../../store/rubrics/actions';
import { BoxModal } from '../modals';
import { MENU } from '../../utils/menu';
import { CATEGORIES } from '../../utils/consts';
import { useLocation } from 'react-router-dom';

type PropsType = {
  level?: number;
};

export const Rubrics: React.FC<PropsType> = ({ level }) => {
  const [categoryId, setCategoryId] = React.useState(CATEGORIES.CONSULTATIONS);
  const [cards, setCards] = React.useState<IRubric[] | null>(null);
  const [isModal, setIsModal] = React.useState(false);

  const { Rubrics: RRubrics, Configurations } = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const category = MENU().find((item) => item.path !== '/' && location.pathname.includes(item.path))?.category;
    if (category) {
      setCategoryId(category);
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (RRubrics.rubrics) {
      if (categoryId && RRubrics.rubrics[categoryId]) {
        setCards(RRubrics.rubrics[categoryId]);
      } else {
        setCards(null);
      }
    }
  }, [RRubrics.rubrics, categoryId]);

  return (
    <>
      <Box mb={1}>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            setIsModal(true);
          }}
        >
          Створити рубрику
        </Button>
      </Box>
      <Tree rubrics={cards} cards={cards} level={level} categoryId={categoryId} setCards={setCards} />
      {isModal ? (
        <BoxModal
          title='Створити рубрику'
          handleClose={() => {
            setIsModal(false);
          }}
          handleConfirm={(value) => {
            dispatch(
              CreateRubric.request({
                data: {
                  mainId: null,
                  categoryId: categoryId,
                  siteId: Configurations.siteId,
                  name: (value as unknown as { title: string; date: Date })?.title || String(value),
                },
              })
            );
            setIsModal(false);
          }}
          open={!!isModal}
        />
      ) : null}
    </>
  );
};
